import React, { useContext } from "react";
import { navigate } from "@reach/router";
import { useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { setUser, isLoggedIn } from "../utils/auth";
import { useFirebase } from "gatsby-plugin-firebase";
import TCCLogo from "../../svg/the-comparison-company-logo.svg";
import LogInPig from "../../svg/loginpig.svg";
import View from "../components/View";
import WindowSizeContext from "../contexts/windowSizeContext";

const Login = () => {
  const [firebase, setFirebase] = useState();

  const windowSize = useContext(WindowSizeContext);

  useFirebase((firebase) => {
    setFirebase(firebase);
  }, []);

  if (isLoggedIn()) {
    navigate(`/app/profile`);
  }

  function getUiConfig(auth) {
    return {
      signInFlow: "redirect",
      signInOptions: [auth.GoogleAuthProvider.PROVIDER_ID, auth.TwitterAuthProvider.PROVIDER_ID, auth.EmailAuthProvider.PROVIDER_ID],
      callbacks: {
        signInSuccessWithAuthResult: (result) => {
          setUser(result.user);
          navigate("/app/profile");
        },
      },
    };
  }

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <View>
      <section className="">
        <div className="container mx-auto">
          <div className="min-h-full py-2 bg-white lg:py-6">
            <div className="flex max-w-sm mx-auto overflow-hidden bg-white border border-gray-100 shadow-lg lg:max-w-4xl">
              <div className={`${windowSize.isLargeScreenView ? `block` : `hidden`} lg:w-1/2 bg-blue-100 py-10 pl-4 pr-6 border-r border-gray-100`}>
                <LogInPig />
              </div>
              <div className="w-full p-8 lg:w-1/2">
                <h2 className="pb-4 text-center text-gray-700">
                  <TCCLogo className="w-3/4 mx-auto" />
                </h2>
                <p className="text-xl text-center text-gray-600">Sign in</p>
                <p></p>
                <div className="flex items-center justify-between my-4">
                  <span className="w-1/5 border-b lg:w-1/4"></span>
                  <p className="text-xs text-center text-gray-500 uppercase">Please select</p>
                  <span className="w-1/5 border-b lg:w-1/4"></span>
                </div>
                <div className="lg:pt-4">{firebase && <StyledFirebaseAuth uiConfig={getUiConfig(firebase.auth)} firebaseAuth={firebase.auth()} />}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </View>
  );
};

export default Login;
