import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import UserProfileReferSectionForm from "../UserProfileReferSectionForm";

export default () => {
  return (
    <>
      <section className="bg-yellow-500 border border-yellow-500">
        <div className="w-full py-4 text-xl text-center text-blue-900">
          <h2 className="text-gray-500">Refer &amp; Earn Points</h2>
        </div>
      </section>
      <section className="py-10 bg-gray-500 border border-gray-500">
        <div className="flex flex-wrap -mx-1 overflow-hidden">
          <div className="w-full px-1 overflow-hidden">
            <h3 className="pt-6 text-center text-yellow-500">Refer friends or a relative and earn special privileges.</h3>
          </div>

          <div className="w-full px-1 overflow-hidden">
            <h3 className="pt-6 text-center text-white">We will send a single email to let them know you thought they should check out The Comparison Company</h3>
          </div>

          <div className="w-full px-1 pt-10 overflow-hidden ">
            <UserProfileReferSectionForm />
          </div>

          <div className="w-full px-1 pb-10 overflow-hidden">
            <p className="py-4 text-sm text-center text-white">
              <Tooltip title="To find out more about the way in which we handle your data see here.">
                <InfoCircleOutlined className="pr-1 align-text-bottom" />
              </Tooltip>
              We won't share any data with any companies outside of The Comparison Company.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
