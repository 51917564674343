import React, { useState } from "react";
import { Input, Button, Notification } from "antd";
import { MailOutlined } from "@ant-design/icons";
import axios from "axios";
import tccNotification from "../../utils/tccNotification";
import { isNullEmptyOrUndefined } from "../../utils/StringUtils";

export default () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const [emailAddress, setEmailAddress] = useState("");

  const handleEmailAddressChange = (evt) => {
    setEmailAddress(evt.target.value);
  };

  const displayNotificationUsing = (notificationArguments) => {
    Notification.open(notificationArguments);
  };

  const handleServerResponse = (ok, msg, form) => {
    const notificationArguments = ok
      ? tccNotification.buildSuccessNotificationArgsFrom("Thanks", "The email address has been received & we will send our latest deals real soon.")
      : tccNotification.buildFailedNotificationArgsFrom(
          "There was an error",
          "Thank you for trying to submit that email address. It seems there was a problem that was likely our fault. Please try again."
        );

    displayNotificationUsing(notificationArguments);

    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
      setEmailAddress("");
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/57a356d8-9ae8-499a-bff8-c23e1d5910c6",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Done", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <form className="flex flex-wrap items-center w-3/4 mx-auto lg:w-1/2 " onSubmit={handleOnSubmit}>
      <div className="inline-flex w-full ml-auto lg:w-1/2">
        <label htmlFor="emailInput" className="hidden" required="required">
          Email address
        </label>
        <Input
          type="email"
          value={emailAddress}
          onChange={handleEmailAddressChange}
          name="email"
          className=""
          id="emailInput"
          aria-describedby="emailHelp"
          size="large"
          placeholder="Email address"
          prefix={<MailOutlined className="pt-1 text-gray-100" />}
        />
        } />
      </div>
      <Button
        htmlType="submit"
        className="inline-flex h-10 py-2 mx-auto mt-2 mb-2 text-gray-500 bg-yellow-500 border border-yellow-500 disabled:text-gray-300 disabled:bg-yellow-500 disabled:border-yellow-500 hover:shadow-xl hover:border-yellow-500 lg:ml-2 hover:bg-gray-500 hover:text-yellow-500"
        disabled={isNullEmptyOrUndefined(emailAddress) || serverState.submitting}
      >
        Refer
      </Button>
    </form>
  );
};
