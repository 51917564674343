import React from "react";
import CardCarousel from "../CardCarousel";
import QuoteSvg from "../../../svg/quote.svg";
import LandingPageTestimonialCard from "../TestimonialCard";
import TestimonialCardText from "../TestimonialCardText";
import TestimonialCardUserDetails from "../TestimonialCardUserDetails";
import TestimonialCardRating from "../TestimonialCardRating";
import testimonialData from "../../data/testimonialData";

export default () => {
  return (
    <>
      {/* <section className="bg-white">
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-1 overflow-hidden">
            <div className="w-full px-1 my-1 overflow-hidden">
              <h1 className="pt-8 pb-4 text-2xl text-center text-blue-900">This is why we do what we do</h1>
            </div>
            <div className="w-full px-1 pt-4 pb-8 my-1 overflow-hidden text-center">
              <p className="">At The Comparison Company we are dedicated to your comfort. But don't just take our workd for it, here is what our customer are saying about us.</p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="bg-gray-300">
        <div className="container mx-auto bg-gray-300 ">
          <QuoteSvg className="h-16 " />
          <div className="flex flex-wrap -mx-1 overflow-hidden ">
            <div className="w-full px-1 pb-10 my-1 overflow-hidden bg-gray-300">
              <div className="relative w-full h-64 mx-auto my-10 ">
                <CardCarousel>
                  {testimonialData.userProfileTestimonials.map((t) => (
                    <LandingPageTestimonialCard>
                      <TestimonialCardText>{t.testimonial}</TestimonialCardText>
                      <TestimonialCardUserDetails user={t.userName} location={t.location} />
                      <TestimonialCardRating rating={t.rating} />
                    </LandingPageTestimonialCard>
                  ))}
                </CardCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
