import React from "react";
import { getUser } from "../utils/auth";
import View from "./View";
import UserRefererSection from "../components/UserProfileReferSection";
import UserProfileTestimonials from "./UserProfileTestimonials";
import UserProfileUserData from "./UserProfileUserData";
import UserProfileCopy from "./UserProfileCopy";

const Profile = () => {
  const user = getUser();

  return (
    <View>
      <UserProfileUserData user={user} />
      <UserProfileCopy />
      <UserRefererSection />
      <UserProfileTestimonials />
    </View>
  );
};

export default Profile;
